import {
  isRouteErrorResponse,
  useMatches,
  useRouteError,
  useLocation,
  Await } from
'@remix-run/react';
import type { ShopifyPageViewPayload } from '@shopify/hydrogen';
import { defer, type LoaderArgs } from '@shopify/remix-oxygen';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
'@remix-run/react';
import type { CustomerAccessToken } from '@shopify/hydrogen-react/storefront-api-types';
import type { HydrogenSession } from '../server';
import favicon from '../public/favicon.ico';
import resetStyles from './styles/reset.css';
import appStyles from './styles/app.css';
import { Layout } from '~/components/Layout';
import tailwindCss from './styles/tailwind.css';
import stylesSwiper from './styles/swiper-bundle.min.css';
import { graphQLService } from './services';
import { CategoryWrapper } from './models';
import { getAllCategories } from './graphql/queries';
import { GenericError, NotFound } from './components/errors';
import { useState, useEffect, useRef } from 'react';
import { useJudgeme } from '@judgeme/shopify-hydrogen';
import { Toaster } from './components/ui';
import {
  useShopifyCookies,
  AnalyticsEventName,
  getClientBrowserParameters,
  sendShopifyAnalytics } from
'@shopify/hydrogen';
import { usePageAnalytics } from '~/hooks/usePageAnalytics';
import { Uppromote } from '@secomus/uppromote-hydrogen';

interface LocationType {
  pathname: string;
  search: string;
}

interface ExtendedShopifyPageViewPayload extends ShopifyPageViewPayload {
  dataLayer?: {
    ecommerce?: any;
  };
}

export const ProductionEnvName = ('production' as const);

export function links() {
  return [
  {
    rel: 'stylesheet preload',
    href: tailwindCss,
    as: 'style'
  },
  {
    rel: 'stylesheet preload',
    href: stylesSwiper,
    as: 'style'
  },
  {
    rel: 'stylesheet preload',
    href: resetStyles,
    as: 'style'
  },
  {
    rel: 'stylesheet preload',
    href: appStyles,
    as: 'style'
  },
  {
    rel: 'preconnect',
    href: 'https://cdn.shopify.com'
  },
  {
    rel: 'preconnect',
    href: 'https://shop.app'
  },
  { rel: 'icon', type: 'image/ico', href: favicon }];

}

export async function loader({ context, request }: LoaderArgs) {
  const { storefront, session, cart } = context;
  const customerAccessToken = await session.get('customerAccessToken');
  const publicStoreDomain = context.env.PUBLIC_STORE_DOMAIN;
  let isSellerHeader = request.url.includes("becomeaseller");
  // validate the customer access token is valid
  const { isLoggedIn, headers } = await validateCustomerAccessToken(
    customerAccessToken,
    session
  );

  // defer the cart query by not awaiting it
  const cartPromise = cart.get();

  // defer the footer query (below the fold)
  const footerPromise = storefront.query(FOOTER_QUERY, {
    cache: storefront.CacheLong(),
    variables: {
      footerMenuHandle: 'footer' // Adjust to your footer menu handle
    }
  });

  // await the header query (above the fold)
  const headerPromise = storefront.query(HEADER_QUERY, {
    cache: storefront.CacheLong(),
    variables: {
      headerMenuHandle: 'main-menu' // Adjust to your header menu handle
    }
  });

  const categories = await graphQLService(context).getGraphQL<CategoryWrapper>(
    getAllCategories
  );

  return defer(
    {
      cart: cartPromise,
      footer: footerPromise,
      header: await headerPromise,
      isLoggedIn,
      publicStoreDomain,
      categories,
      storeHeader: !isSellerHeader,
      sellerHeader: isSellerHeader,
      judgeme: {
        shopDomain: context.env.JUDGEME_SHOP_DOMAIN,
        publicToken: context.env.JUDGEME_PUBLIC_TOKEN,
        cdnHost: context.env.JUDGEME_CDN_HOST,
        delay: 500 // optional parameter, default to 500ms
      },
      socialNetwork: {
        facebook: context.env.FACEBOOK,
        tiktok: context.env.TIKTOK,
        instagram: context.env.INSTAGRAM,
        linkedin: context.env.LINKEDIN,
        sellerLogin: context.env.SELLER_LOGIN
      },
      upPromote: {
        upPromoteStoreDomain: context.env.UPPROMOTE_STOREDOMAIN,
        upPromoteStorefrontApiToken: context.env.UPPROMOTE_APITOKEN,
        upPromoteStorefrontApiVersion: context.env.UPPROMOTE_APIVERSION,
        upPromoteAccessToken: context.env.UPPROMOTE_ACCESSTOKEN
      },
      klaviyo: {
        publicApiKey: context.env.KLAVIYO_PUBLIC_API_KEY
      },
      minimumPurchase: context.env.MINIMUM_PURCHASE,
      recaptchaGoogle: context.env.RECAPTCHA_V2_SITE_KEY,
      purchaseForFreeShipping: context.env.PURCHASE_FOR_FREE_SHIPPING,
      gtmContainerID: context.env.GTM_CONTAINER_ID || 'GTM-TWVD3XP4',
      gscVerification: context.env.GSC_VERIFICATION || '',
      request: new URL(request.url),
      envName: context.env.ENV_NAME
    },
    { headers }
  );
}

export default function App() {
  const data = useLoaderData<any>();
  const [openCart, setOpenCart] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [mainSearch, setMainSearch] = useState('');
  const [canonicalUrl, setCanonicalUrl] = useState('');

  useJudgeme(data.judgeme);
  const hasUserConsent = true;
  useShopifyCookies({ hasUserConsent });

  const location = useLocation();
  const lastLocation = useRef<LocationType>({ pathname: '', search: '' });
  const pageAnalytics = (usePageAnalytics({
    hasUserConsent,
    shopId: data?.header?.shop?.id,
    currency: 'USD',
    acceptedLanguage: 'en'
  }) as ExtendedShopifyPageViewPayload);

  useEffect(() => {
    // GTM code
    const gtmScript = `
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${data?.gtmContainerID}');
    `;

    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = gtmScript;
    document.head.appendChild(scriptElement);
  }, []);

  useEffect(() => {
    if (lastLocation.current.pathname === location.pathname && lastLocation.current.search === location.search) return;

    lastLocation.current.pathname = location.pathname;
    lastLocation.current.search = location.search;

    const payload: ExtendedShopifyPageViewPayload = {
      ...getClientBrowserParameters(),
      ...pageAnalytics
    };

    sendShopifyAnalytics({
      eventName: AnalyticsEventName.PAGE_VIEW,
      payload
    });

    let dataLayer = window.dataLayer || [];
    dataLayer.push({ event: 'page_view' });

    if (pageAnalytics?.dataLayer) {
      if (pageAnalytics?.dataLayer?.ecommerce) {
        dataLayer.push({ ecommerce: null });
      }
      dataLayer.push(pageAnalytics?.dataLayer);
    }
  }, [location, pageAnalytics]);

  useEffect(() => {
    return setCanonicalUrl(new URL(window.location.href).origin + location.pathname + location.search);
  }, []);

  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.setAttribute(
      'src',
      `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${data.klaviyo.publicApiKey}`);
    document.head.appendChild(scriptElement);
  }, []);

  return (
    <html lang="en" className="scroll-smooth">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {data?.gscVerification.length ?
        <meta name="google-site-verification" content={data?.gscVerification} /> :
        ''}
        {data?.envName !== ProductionEnvName ? <meta name="robots" content="noindex, nofollow" /> : null}
        <Meta />
        <Links />
        {/* Set the dynamic canonical tag */}
        <link rel="canonical" href={canonicalUrl} />
      </head>
      <body>
        <Layout
          {...data}
          openCart={openCart}
          setOpenCart={setOpenCart}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          mainSearch={mainSearch}
          setMainSearch={setMainSearch}>

          <Outlet context={{ openCart, setOpenCart, openMenu, setOpenMenu, mainSearch, setMainSearch }} />
        </Layout>
        <ScrollRestoration />
        <Scripts />
        <Toaster />
        <Await resolve={data.cart}>
          {(cart) =>
          <Uppromote
            cart={cart}
            publicStoreDomain={data.upPromote.upPromoteStoreDomain}
            publicStorefrontApiToken={data.upPromote.upPromoteStorefrontApiToken}
            publicStorefrontApiVersion={data.upPromote.upPromoteStorefrontApiVersion}
            uppromoteAccessToken={data.upPromote.upPromoteAccessToken} />}

          </Await>
      </body>
    </html>);

}

export function ErrorBoundary() {
  const data = useLoaderData<any>();
  const error = (useRouteError() as any);
  const [root] = useMatches();
  let errorMessage = 'Unknown error';
  let errorStatus = 500;

  if (isRouteErrorResponse(error) || validateProductNotFound(error.message)) {
    errorStatus = 404;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {data?.gscVerification.length ?
        <meta name="google-site-verification" content={data?.gscVerification} /> :
        ''}
        {data?.envName !== ProductionEnvName ? <meta name="robots" content="noindex, nofollow" /> : null}
        <Meta />
        <Links />
      </head>
      <body>
        <Layout {...root.data}>
          <div className="route-error">
            {errorStatus === 404 ?
            <NotFound /> :

            <GenericError errorMessage={errorMessage} />}

          </div>
        </Layout>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>);

}

const validateProductNotFound = (error: string) => {
  return error.search('PRODUCT_NOT_FOUND') >= 0;
};

/**
 * Validates the customer access token and returns a boolean and headers
 * @see https://shopify.dev/docs/api/storefront/latest/objects/CustomerAccessToken
 *
 * @example
 * ```ts
 * //
 * const {isLoggedIn, headers} = await validateCustomerAccessToken(
 *  customerAccessToken,
 *  session,
 *  );
 *  ```
 *  */
async function validateCustomerAccessToken(
customerAccessToken: CustomerAccessToken,
session: HydrogenSession)
{
  let isLoggedIn = false;
  const headers = new Headers();
  if (!customerAccessToken?.accessToken || !customerAccessToken?.expiresAt) {
    return { isLoggedIn, headers };
  }
  const expiresAt = new Date(customerAccessToken.expiresAt);
  const dateNow = new Date();
  const customerAccessTokenExpired = expiresAt < dateNow;
  if (customerAccessTokenExpired) {
    session.unset('customerAccessToken');
    headers.append('Set-Cookie', await session.commit());
  } else {
    isLoggedIn = true;
  }

  return { isLoggedIn, headers };
}

const MENU_FRAGMENT = (`#graphql
  fragment MenuItem on MenuItem {
    id
    resourceId
    tags
    title
    type
    url
  }
  fragment ChildMenuItem on MenuItem {
    ...MenuItem
  }
  fragment ParentMenuItem on MenuItem {
    ...MenuItem
    items {
      ...ChildMenuItem
    }
  }
  fragment Menu on Menu {
    id
    items {
      ...ParentMenuItem
    }
  }
` as const);

const HEADER_QUERY = (`#graphql
  fragment Shop on Shop {
    id
    name
    description
    primaryDomain {
      url
    }
    brand {
      logo {
        image {
          url
        }
      }
    }
  }
  query Header(
    $country: CountryCode
    $headerMenuHandle: String!
    $language: LanguageCode
  ) @inContext(language: $language, country: $country) {
    shop {
      ...Shop
    }
    menu(handle: $headerMenuHandle) {
      ...Menu
    }
  }
  ${MENU_FRAGMENT}
` as const);

const FOOTER_QUERY = (`#graphql
  query Footer(
    $country: CountryCode
    $footerMenuHandle: String!
    $language: LanguageCode
  ) @inContext(language: $language, country: $country) {
    menu(handle: $footerMenuHandle) {
      ...Menu
    }
  }
  ${MENU_FRAGMENT}
` as const);